/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url("https://fonts.googleapis.com/css2?family=Libre+Bodoni&display=swap");
* {
  font-family: "poppins", sans-serif;
}

ion-label {
  font-family: "poppins", sans-serif !important;
  color: black;
}

.split-pane-visible > .split-pane-side {
  min-width: 290px !important;
  max-width: 290px !important;
}

.container-fluid {
  width: auto !important;
  margin: 10px 10px 10px 10px;
  border-radius: 13px;
  background-color: white;
  padding-bottom: 1.5rem;
}

.small-modal::part(content) {
  height: 75%;
  width: 50%;
  border-radius: 14px;
  box-shadow: 0px 2px 50px 0px rgb(63, 63, 63);
}

.content {
  --background:#FFE8E8;
}

.footer {
  border-top: 0.5px solid rgba(226, 226, 226, 0.884);
}

.swiper-pagination {
  bottom: 0px !important;
}

.hideSmall {
  display: block;
}

@media (max-width: 996px) {
  .hideSmall {
    display: none !important;
  }
  .menu .split-pane-visible > .split-pane-side {
    min-width: auto;
    max-width: auto;
  }
  .container-fluid {
    width: auto !important;
    margin: auto;
    border-radius: 13px;
  }
}
.my-alert-class .alert-wrapper {
  border-radius: 30px;
}
.my-alert-class .alert-wrapper .alert-head .alert-title {
  color: var(--ion-color-primary);
}
.my-alert-class .alert-wrapper button.alert-button:nth-child(1) {
  color: red;
}
.my-alert-class .alert-wrapper button.alert-button:nth-child(2) {
  color: var(--ion-color-primary);
}
.my-alert-class .alert-wrapper .alert-button {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 600;
}

.my_custom_class::part(content) {
  position: absolute;
  right: 20px !important;
  top: 50px !important;
  left: auto;
}

.picker-wrapper {
  position: absolute;
  top: 60px !important;
  bottom: 130px !important;
}

.td1, .th1 {
  border: 1px solid #777;
  padding: 0.5rem;
  text-align: center;
}

.th1 {
  background: rgb(128, 2, 2) !important;
  color: rgb(233, 181, 49);
}

.table1 {
  border-collapse: collapse !important;
  border-radius: 18px !important;
  border-color: #b9b9cb !important;
}

.tbody1 .tr1 {
  background: rgb(223, 218, 218);
}

.alert-radio-group::-webkit-scrollbar, .alert-checkbox-group::-webkit-scrollbar {
  width: 0.7em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track, .alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb, .alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

ion-datetime-button::part(native) {
  background: transparent;
  border: none;
}

.popover-datetime::part(content) {
  width: 23vw;
}

@media screen and (max-width: 900px) {
  .MobResCal {
    width: 100% !important;
    align-items: auto;
  }
  .MobResCal1 {
    width: 100% !important;
  }
}
.ion-modal-cal {
  --width: 290px;
  --height: 390px;
  --border-radius: 8px;
}

.ion-modal-calentime {
  --width: 290px;
  --height: 400px;
  --border-radius: 8px;
}

.ion-modal-caltime {
  --width: 290px;
  --height: 250px;
  --border-radius: 8px;
}

ion-modal ion-datetime {
  height: 382px;
}